// react
import React, { useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";

// blocks
import BlockSlideShow from "../blocks/BlockSlideShow";
import StroykaSlick from "../shared/StroykaSlick";
import BlockVideoSlideShow from "../blocks/BlockVideoSlideShow";

// data stubs
import theme from "../../data/theme";
import { getProductCategories, getProductList } from "../../api/product";
import { Link } from "react-router-dom";
import { decodeChineseText, getNoImg, url } from "../../services/utils";
import ProductCard from "../shared/ProductCard";
import { getHomeBanner, getPromotionBanners } from "../../api/general";
import BlockLoader from "../blocks/BlockLoader";
import { ArrowRoundedRight8x13Svg } from "../../svg";
import { useSelector } from "react-redux";
const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    // autoplay: true,
    speed: 2500,
    autoplaySpeed: 7000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function HomePage() {
    const [loading, setLoading] = useState(true);
    const [latestProducts, setLatestProducts] = useState([]);
    const [bestSellProducts, setBestSellProducts] = useState([]);
    const [featuresProducts, setFeaturesProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [banners, setBanners] = useState([]);
    const [promoBanners, setPromoBanners] = useState([]);
    const [videos, setVideos] = useState(null);
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);

    const intl = useIntl();
    useEffect(() => {
        getHomeBanner().then((res) => {
            setBanners(res);
        });
        getProductCategories({ limit: "0,10" }).then((res) => {
            setCategories(res);
        });
        getPromotionBanners().then((res) => {
            setPromoBanners(res?.banner_info);
        });

        let arr = [
            { media_url: 'https://youtu.be/ef4gCuOQ5xc?si=WkX3Sxoyu9OYuOCO', title: "videoSection.video1" },
            { media_url: 'https://youtu.be/POstbhJjqqU', title: "videoSection.video2" },
            { media_url: 'https://youtu.be/31_sEawfd18', title: "videoSection.video3" },
            { media_url: 'https://youtu.be/XgOT4dUkcAM', title: "videoSection.video4" },
            { media_url: 'https://youtu.be/4YpoEI0Odsc', title: "videoSection.video5" },
            { media_url: 'https://youtu.be/v3iNPhhbUUE', title: "videoSection.video6" },
        ]
        setVideos(arr)
    }, []);

    useEffect(() => {
        getProductList({ limit: "0,10", type: "homepage" }).then((res) => {
            setFeaturesProducts(res);
            setLoading(false);
        });
        getProductList({ limit: "0,10", type: "bestseller" }).then((res) => {
            setBestSellProducts(res);
            setLoading(false);
        });
        getProductList({ limit: "0,10", sort: "latest" }).then((res) => {
            setLatestProducts(res);
            setLoading(false);
        });
    }, [deliveryMethod?.store?.store_code]);

    if (loading) {
        return <BlockLoader />;
    }

    const renderProductSection = (list, title, slug) => {
        return list && list.length > 0 ? (
            <div className="container py-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <h4 className="mb-0 p-2">{title}</h4>
                    <Link to={{ pathname: "/product-list/" + slug }} className="d-flex flex-wrap align-items-center">
                        <FormattedMessage id="view_more" defaultMessage="View More" />
                        <ArrowRoundedRight8x13Svg
                            className="page-link__arrow page-link__arrow--right text-color-theme mx-2"
                            aria-hidden="true"
                        />
                    </Link>
                </div>
                <div className="px-sm-0 block products-view__list products-list" data-layout={"grid-5-full"}>
                    <div className="products-list__body">
                        {list.map((product) => {
                            return (
                                <div key={product.product_code} className="products-list__item">
                                    <ProductCard product={product} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        ) : null;
    };

    function redirectToCategory(cat) {
        return {
            pathname: url.category(cat),
            state: {
                lineName: cat.line_name,
            },
        };
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "homepage.home", defaultMessage: "Home Page" })} — ${theme.name}`}</title>
            </Helmet>

            <BlockVideoSlideShow banners={videos} />
            <BlockSlideShow banners={banners} />

            {/* <BlockFeatures /> */}

            {categories && categories.length > 0 ? (
                <div className="container py-4">
                    <h4 className="p-2">
                        <FormattedMessage id="category" defaultMessage="Category" />
                    </h4>
                    <div className="vproduct-section-3">
                        <div className="row no-gutters pl-0 pr-0">
                            {categories.map((cat, index) => {
                                return (
                                    <div key={cat.line_code} className="category-box">
                                        <div className="img-wrapper">
                                            <Link to={redirectToCategory(cat)}>
                                                <img
                                                    src={cat.line_icon?.[0] ? cat.line_icon : getNoImg()}
                                                    onError={(e) => (e.target.src = getNoImg())}
                                                    alt={cat.line_name}
                                                />
                                            </Link>
                                        </div>
                                        <div className="item-detail">
                                            <Link to={redirectToCategory(cat)}>{decodeChineseText(cat.line_name)}</Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : null}

            {promoBanners && promoBanners?.length > 0 ? (
                <div className="container pt-3">
                    <h4 className="p-2">
                        <FormattedMessage id="promotion" defaultMessage="Promotions" />
                    </h4>
                    <div className="block-slideshow__body">
                        <StroykaSlick {...slickSettings}>
                            {promoBanners.map((promo, index) => (
                                <Link
                                    key={promo.banner_id}
                                    to={{ pathname: "/promotion/" + promo.banner_id, state: { promotion: promo } }}
                                    className="block-event__slide"
                                >
                                    <img className="img-fluid" src={promo?.banner_image} onError={(e) => (e.target.src = getNoImg())} />
                                </Link>
                            ))}
                        </StroykaSlick>
                    </div>
                </div>
            ) : null}

            {renderProductSection(featuresProducts, <FormattedMessage id="features_prod" defaultMessage="Features Products" />, "features")}

            {renderProductSection(bestSellProducts, <FormattedMessage id="hottest_prod" defaultMessage="Hottest Products" />, "hottest")}

            {renderProductSection(latestProducts, <FormattedMessage id="latest_prod" defaultMessage="Latest Products" />, "latest")}
        </React.Fragment>
    );
}

export default HomePage;
